import React from 'react';
import { Title } from '@vds/typography'
import { Button } from '@vds/buttons';
import { Link } from 'react-router-dom'
import { Line } from '@vds/lines';

export default function Welcome () {
  return (
    <div className="container mx-auto h-screen w-100 sm:w-3/4 md:w-1/2 flex flex-col items-center justify-center">
      <div className="w-100 mb-4 flex justify-center">
        <Title size="medium" primitive="h1">Welcome to Verizon Purpose Coin</Title>
        <Line type="heavy"/>
      </div>

      <div className="w-100 d-flex justify-content-left">
        <Link to="/coins/new">
          <Button>Register</Button>
        </Link>
      </div>
    </div>
  )
}
