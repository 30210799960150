import React from 'react';
import { Title, Body } from '@vds/typography'
import axios from 'axios'
import { apiURL } from '../constants'
import { Context } from '../Store.js'
import { useHistory } from 'react-router-dom'
import { Line } from '@vds/lines';


function TransferCard ({user, created_at, index}) {
  return (
    <div className='transfer-card'>
      <div className='left-side'>
        <div className='transfer-count-container'>
          <div className='transfer-count'>
            { index + 1}
          </div>
        </div>
        <div className="user-info">
          <Body>{`${user.first_name} ${user.last_name}`}</Body>
        </div>
      </div>
      <div className="date">
      <Body>{`${created_at}`}</Body>
      </div>

    </div>
  )
}

function FollowCoin () {

  const {dispatch, state} = React.useContext(Context)
  const { user, transfers, coin } = state
  const history = useHistory()

  React.useEffect(() => {
    fetchCoinTransfers()
  }, [])

  if (!user || !coin) {
    history.replace('/coin')
    return null
  }

  async function fetchCoinTransfers () {
    try {
      const res = await axios.get(`${apiURL}/coins/${coin.id}/transfers.json`)
      dispatch({
        type: "SET_TRANSFERS",
        transfers: res.data.transfers
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="container mx-auto w-100">
      <div className="p-6">
        <div className="w-100 mb-4 leading-loose">
          <Title size="large" primitive="h1">{`Welcome Back${user ? `, ${user.first_name}` : ''}.`}</Title>
          <Line type="heavy"/>
        </div>

        <div className="w-100 mb-4">
          <div className="">
            <Body size="large" viewport="desktop" primitive="h2">Check out where all the Purpose Coins have been. And watch as we move #ForwardTogether.</Body>
          </div>
        </div>

        {
          transfers &&
            transfers.map(({created_at, user}, index) => {
              return <TransferCard
                index={index}
                user={user}
                created_at={created_at}
              />
            })
        }
      </div>

      <div className="p-6">
      </div>

    </div>
  )
}

export default FollowCoin;
