import React from 'react';
import { Title } from '@vds/typography'
import { Body } from '@vds/typography';
import { DropdownSelect } from '@vds/selects';
import { InputField } from '@vds/inputs';
import { Button } from '@vds/buttons';
import TextAreaField from './TextAreaField'
import axios from 'axios'
import { Formik } from "formik"
import { apiURL } from '../constants'
import { Context } from '../Store.js'
import { Notification } from '@vds/notifications';
import { Line } from '@vds/lines';
import ConnectedNotification from './ConnectedNotification'

export default function Help () {
  const [success, setSuccess] = React.useState(null)
  const { dispatch } = React.useContext(Context)
  const [reason, setReason] = React.useState('Request Coin')

  async function submitForm ({values, setSubmitting}) {

    try {
      const res = await axios.post(`${apiURL}/contacts.json`, values, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      if (res) {
        setSuccess(true)
      }
    } catch (e) {
      dispatch({ type: 'SET_ERROR', error: "Looks like we have a problem. Please refresh this page."})
    } finally {
      setSubmitting(false)

    }
  }

  function handleCloseSuccess () {
    setSuccess(null)
  }

  function handleReasonChange (event) {
    setReason(event.target.value)
  }

  return (
    <div className="container mb-4">

      <div className="p-6">
      <ConnectedNotification />
      {
        success &&
        <Notification onCloseButtonClick={handleCloseSuccess} type='success'>
          Thank you. We've received your message and will reach out to you shortly.
        </Notification>
      }

      <div className="w-3/4 mb-4">
        <Title size="large" primitive="h1">Help</Title>
        <Line type="heavy"/>
      </div>

      <div className="w-100 mb-4">
        <Body size="large" viewport="desktop" primitive="h2">How can we help you?</Body>
      </div>

      <Formik
        initialValues={{ explanation: '',  email: '' }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Email Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          if (!values.explanation) {
            errors.reason = 'Give us a reason';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm, initialValues }) => {

          const mergedValues = Object.assign({}, values, { reason })

          submitForm({
            values: mergedValues,
            setSubmitting: setSubmitting
          })

          resetForm(initialValues)
        }}
        >
        {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
        }) => (
        <form className="help-form" onSubmit={handleSubmit}>
          <div className="w-100 mb-4">
            <div className="w-full pr-12">
              <DropdownSelect
                className="mb-4"
                label="Select One"
                errorText="State is required"
                width="inherit"
                id="reason"
                name="reason"
                onChange={handleReasonChange}
              >
                <option>Request Coin</option>
                <option>I lost my coin</option>
                <option>Other</option>
              </DropdownSelect>
              <TextAreaField
                placeholder="Tell us more"
                width="inherit"
                type="textArea"
                name="explanation"
                // type="notes"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.explanation}
                error={!!errors.explanation && !!touched.explanation}
                errorText={errors.explanation}
              />
              <InputField
                className="hello"
                label="What is your email address?"
                placeholder="Recipient's Email"
                width="inherit"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={!!errors.email && !!touched.email}
                errorText={errors.email}
              />
            </div>
          </div>
          <div className="w-100 flex justify-center">
            <Button className="w-100-mobile" type='submit' disabled={isSubmitting}>Submit</Button>
          </div>
        </form>
        )}
      </Formik>
      </div>
    </div>
  )
}
