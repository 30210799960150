import React from 'react';
import { Title } from '@vds/typography'
import { Body } from '@vds/typography';
import TextAreaField from './TextAreaField'
import { Button } from '@vds/buttons';
import { Formik } from 'formik'
import { Context } from '../Store.js'
import { updateRecipientFormFieldValues } from '../formHelpers'
import { useHistory } from "react-router-dom";
import { Line } from '@vds/lines';
import ConnectedNotification from './ConnectedNotification'


export default function RecipientStepTwo () {

  const { state, dispatch } = React.useContext(Context)
  const { recipientFormWizard, coin } = state
  const { formFields } = recipientFormWizard
  const coin_id = coin.id
  let history = useHistory()

  return (
    <div className="container mx-auto w-100 sm:w-3/4 md:w-1/2 flex flex-col items-center justify-center">
      <ConnectedNotification />
      <div className="p-6">
        <div className="w-100  mb-4">
          {/* <Title size="large" primitive="h1">{`Hey, ${user.first_name}.`}</Title> */}
          <Title size="large" primitive="h1">What purpose looks like</Title>
          <Line type="heavy"/>
        </div>

        <div className="w-100 mb-4">
          <Body size="large" viewport="desktop" primitive="h4">In a few words, tell us why you chose <strong>{`${formFields.first_name} ${formFields.last_name}`}</strong> to become a Verizon Purpose Champion.</Body>
        </div>

        <Formik
          initialValues={{  notes: '' }}
          validate={values => {
            const errors = {};

            if (!values.notes) {
              errors.notes = 'Notes Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            updateRecipientFormFieldValues({
              submitting: true,
              dispatch,
              recipientFormWizard,
              values,
              setSubmitting,
              coin_id,
              history
            })
          }}
        >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="w-100 mb-4 flex justify-center">
            <div className="w-100">
              <TextAreaField
                placeholder="How does the individual specifically embody Verizon's shared purpose?"
                width="inherit"
                type="textArea"
                name="notes"
                // type="notes"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.notes}
                error={!!errors.notes && !!touched.notes}
                errorText={errors.notes}
              />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-left">
            <Button className="w-100-mobile" type="submit" disabled={isSubmitting}>
              Next
            </Button>
          </div>
        </form>
      )}
    </Formik>

    </div>
  </div>


  )
}
