import React, { useState } from 'react';
import { Title } from '@vds/typography'
import { Subtitle } from '@vds/typography'
import { Body } from '@vds/typography';
import { Button } from '@vds/buttons';
import { Context } from '../Store.js'
import {useDropzone} from 'react-dropzone';
import Upload from '../assets/Upload.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { apiURL } from '../constants'
import { useHistory } from 'react-router-dom'
import { Line } from '@vds/lines';
import { Loader } from '@vds/loaders'

import { Notification } from '@vds/notifications';

export default function FileUpload () {
  const [imageFiles, setImageFiles] = useState([])
  const [videoFiles, setVideoFiles] = useState([])
  const [filePreviews, setFilePreviews] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(null)

  const { state, dispatch } = React.useContext(Context)
  const { transfer } = state

  let history = useHistory()

  async function handleSubmit () {

    if (submitting) {
      return
    }

    let fileToUpload = null

    if (imageFiles && imageFiles.length > 0) {
      fileToUpload = imageFiles[0]
    }

    if (!fileToUpload && videoFiles && videoFiles.length > 0 ) {
      fileToUpload = videoFiles[0]
    }


    if (!fileToUpload) {
      setFileUploadError("It doesn't look like you’ve attached anything. If that's intentional, feel free to skip the step. Otherwise please upload your file now.")
      return
    }

    var formData = new FormData();
    formData.append("files", fileToUpload);
    setSubmitting(true)
    try {
      const headers = {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
      const res = await axios.post(`${apiURL}/transfers/${transfer.id}/upload_file`, formData, { headers: headers })
      dispatch({
        type: "SET_UPLOADED_FILEPATH",
        uploaded_filepath: `${apiURL}/${res.data}`
      })
      history.push('/thank_you')
    } catch (error) {
      console.log(error.response)
      setFileUploadError('something went wrong, try a smaller file')
    } finally {
      setSubmitting(false)
    }
  }

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: 'video/*,image/*',
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0].type.startsWith("video")) {
        setImageFiles([])
        setFilePreviews([])
        setVideoFiles(acceptedFiles)
      } else {
        setVideoFiles([])
        const newFilePreviews = acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        })
        setImageFiles(acceptedFiles)
        setFilePreviews(newFilePreviews);
      }
    }
  });

  function handleRemoveClick () {
    setImageFiles([])
    setVideoFiles([])
    setFilePreviews([])
  }


  function clearFileUploadError () {
    setFileUploadError(null)
  }


  const showImageThumbNails = filePreviews && filePreviews.length > 0
  const showVideoOverlay = !showImageThumbNails && videoFiles && videoFiles.length > 0

  const videoOverlayStyles = 'photo-uploader video-overlay d-flex align-items-center'
  const defaultBorderStyles = `photo-uploader mb-4– d-flex justify-content-center text-center align-items-center ${filePreviews.length > 0 ? 'border-solid border-2 border-gray-600' : ''}`

  return (
    <div className="container file-upload-page">
      <Loader active={submitting} />
      {/* does not need global notification */}
      {
        fileUploadError &&
        <div className='pb-4'>
        <Notification onCloseButtonClick={clearFileUploadError} disableAnimation={true} type="error" id="error-bar" >
          {fileUploadError}
        </Notification>
        </div>
      }

      <div className="p-6">
        <div className="w-full mb-2">
          {/* <Title size="large" primitive="h1">{`Hey, ${user.first_name}.`}</Title> */}
          <Title size="large" primitive="h1">What purpose looks like</Title>
          <Line type="heavy"/>
        </div>

        <div className="w-full mb-4">
          <Body size="large" viewport="desktop" primitive="h4">
            Please include a photo or video to help tell your story.
          </Body>
        </div>

        <div className="w-full"></div>
        <section className={ showVideoOverlay ? videoOverlayStyles : defaultBorderStyles }>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            {
              showImageThumbNails
              ? <Thumbs filePreviews={filePreviews} />
              : showVideoOverlay
                ? <VideoOverlay />
                : <UploadOverlay />
            }
          </div>
        </section>
      </div>

      {
        (showImageThumbNails || showVideoOverlay) &&
          <div className='d-flex pt-4' onClick={handleRemoveClick}>
            <Subtitle>remove</Subtitle>
          </div>
      }

    <div className="file-upload-footer mt-4 d-flex justify-content-around">
      <Button className="even-space" onClick={handleSubmit} disabled={submitting} type="submit">
        Submit
      </Button>
      <Link className="even-space" to="/thank_you">
        <Button type="secondary">Skip</Button>
      </Link>
    </div>
    </div>
  );
}

function VideoOverlay () {
  return (
    <div className="d-flex justify-content-center align-items-start flex-column">
      <div className="mb-5 w-100 text-center">
        <Title size="small" primitive="h4">
          Your video is ready for upload!
        </Title>
      </div>
    </div>
  )
}

function Thumbs ({filePreviews}) {
  return (
    <div className="absolute inset-0 object-cover flex items-center justify-center">
      {
        filePreviews.map((file) => {
          return (
            <div key={file.name} >
              <div>
                <img alt='file preview' src={file.preview} style={{maxHeight: '200px'}}/>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

function UploadOverlay () {
  return (
    <div className="d-flex justify-content-center text-center align-items-center flex-column border-dashed border-2 border-gray-600">
    <div className="mb-3">
      <img src={Upload} alt="Logo" className="upload-icon object-contain" />
    </div>
    <div className="mb-5">
      <Title size="small" primitive="h4">
        Choose a file
      </Title>
    </div>
    <Button type="secondary">
      Upload
    </Button>
  </div>
  )
}
