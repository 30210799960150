import React, { useState } from 'react';
import { InputField } from '@vds/inputs';
import { Button } from '@vds/buttons';
import { Body } from '@vds/typography';
import Logo from '../assets/logo-black.png'
// import * as yup from 'yup';

// let schema = yup.object().shape({
//   coinNumber: yup.number().required()
// })

function RecipientEmailInput(props) {
  const [inputEntered, setinputEntered] = useState(false)

  function handleClick () {
    // window.location = `/confirm-info`
    window.location =`/confirm-info?referral=${props.referral}`
  }

  function handleChange(field) {
    if (field.target.value.length > 0) {
      setinputEntered(true)
    } else {
      setinputEntered(false)
    }
  }

  return (
    <div className="container mx-auto mt-10 w-5/6 sm:w-3/4 md:w-1/2 flex flex-col items-center justify-center">
      <div className="w-100 mx-auto">
        <img src={Logo} alt="Logo" />
      </div>

      <div className="w-100 mt-10 mb-4 flex justify-center">
        <Body size="large" viewport="desktop" primitive="p">Congatulations on being selected to receive a <strong>Purpose Coin.</strong></Body>
      </div>

      <div className="w-100 mb-4 flex justify-center">
        <Body size="large" viewport="desktop" primitive="p">Your participation helps spread out new purpose and strengthen our commitment to moving <strong>#ForwardTogether.</strong></Body>
      </div>

      <div className="w-100 mb-4 flex justify-center">
        <Body size="large" viewport="desktop" primitive="p">We know you're excited to recognize and reward someone who shows purpose in action in all that they do.</Body>
      </div>

      <div className="w-100 mb-4 flex justify-center">
        <Body size="large" viewport="desktop" primitive="p">Before you can carry the movement forward and find the next person who deserves to be recognized, you'll need to confirm your possession by registering your coin's unique number below.</Body>
      </div>

      <div className="w-100 mb-4 flex justify-center">
        <div className="w-100">
          <InputField
            placeholder="Your Email"
            width="inherit"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="w-100 flex justify-center">
        <div className="">
          <Button onClick={handleClick} disabled={!inputEntered}>Submit</Button>
        </div>
      </div>
    </div>
  )
}

export default RecipientEmailInput;
