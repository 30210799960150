
import React from 'react'

export default function TextAreaField ({value, handleChange, handleBlur, placeholder, errorText, name}) {
  return (
    <React.Fragment>
      <textarea 
        rows="4" 
        cols="50" 
        placeholder={placeholder} 
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        name={name}
        className={`text-area ${errorText && 'text-area-error'}`}
      >
        {value}
      </textarea>
      {
        errorText && 
          <div className='error-text'>
            {errorText}
          </div>
      }
  </React.Fragment>
  )
}