import React from 'react';
import { Title } from '@vds/typography'
import { Context } from '../Store.js'
import { Line } from '@vds/lines';

export default function RecipientStepThree () {

  const { state } = React.useContext(Context)
  const { user } = state

  return (
    <div className="container mx-auto w-100 sm:w-3/4 md:w-1/2 flex flex-col items-center justify-center">
      <div className="p-6">
        <div className="w-100 mb-16">
          <Title size="large" primitive="h1">{`Hey, ${user.first_name}.`}</Title>
          <Line type="heavy"/>
        </div>
      </div>
    </div>
  )
}
