import React from 'react';
import Screens from './components'
import Nav from './components/Nav'
import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { Context } from './Store.js'

import ThankYou from './components/ThankYou'
import VerifyCoinWizard from './components/VerifyCoinWizard'
import RecipientConfirmation from './components/RecipientConfirmation'
import SuccessfulRegistration from './components/SuccessfulRegistration'
import FollowCoin from './components/FollowCoin'
import FileUpload from './components/FileUpload'
import About from './components/About'
import Help from './components/Help'
import LeaderWelcome from './components/LeaderWelcome'
import ErrorBoundary from './components/ErrorBoundary'
import TestErrorBoundary from './components/TestErrorBoundary'
import LandingPage from './components/LandingPage'


// const withoutNavScreen = ['/', '/coin', '/verify_coin', '/coins/new']
const withoutNavScreen = ['/']
window.currentLocation = null


export default function Routes () {
  const { dispatch } = React.useContext(Context)
  // const { Welcome, CoinInput, PassCoin, RecipientFormWizard  } = Screens
  const { Welcome, PassCoin, RecipientFormWizard  } = Screens
  let location = useLocation()
  console.log(location)
  React.useEffect(() => {

  }, [])

  if (!window.currentLocation) {
    window.currentLocation = location
  }

  if (window.currentLocation && window.currentLocation.pathname !== location.pathname) {
    window.currentLocation = location
    dispatch({ type: 'CLEAR_ERROR' })
  }

  const hideLogo = ['/', '/coin'].includes(location.pathname)

  return (
    <React.Fragment>
      {
        !withoutNavScreen.includes(location.pathname) && <Nav hideLogo={hideLogo} />
      }

      <Switch>
        {/* INITIALIZE FLOW */}
        <Route path='/welcome' render={() => (
          <ErrorBoundary>
            <Welcome />
          </ErrorBoundary>
        )} />
        {/* <Route path='/coins/new' component={DarkCoinInput} /> */}
        <Route path='/pass_coin/:coin_id' render={() => (
          <ErrorBoundary>
            <PassCoin />
          </ErrorBoundary>
        )} />
        <Route path='/select_recipient' render={() => (
          <ErrorBoundary>
            <RecipientFormWizard />
          </ErrorBoundary>
        )} />

        <Route path='/upload' render={() => (
          <ErrorBoundary>
            <FileUpload />
          </ErrorBoundary>
        )} />

        <Route path='/thank_you' render={() => (
          <ErrorBoundary>
            <ThankYou />
          </ErrorBoundary>
        )} />
        {/* INITIALIZE FLOW */}

        {/* USER FLOW */}
        <Route path='/coin' render={() => (
          <ErrorBoundary>
            <VerifyCoinWizard />
          </ErrorBoundary>
        )} />
        <Route path='/leader_welcome' render={() => (
          <ErrorBoundary>
            <LeaderWelcome />
          </ErrorBoundary>
        )} />
        <Route path='/recipient_confirmation' render={() => (
          <ErrorBoundary>
            <RecipientConfirmation />
          </ErrorBoundary>
        )} />
        <Route path='/successful_registration' render={() => (
          <ErrorBoundary>
            <SuccessfulRegistration />
          </ErrorBoundary>
        )} />
        <Route path='/follow_coin/:coin_id' render={() => (
          <ErrorBoundary>
            <FollowCoin />
          </ErrorBoundary>
        )} />
        {/* USER FLOW */}
        {/* NAV ITEMS */}
        <Route path='/about' render={() => (
          <ErrorBoundary>
              <About />
          </ErrorBoundary>
        )} />
        <Route path='/help' render={() => (
          <ErrorBoundary>
              <Help />
          </ErrorBoundary>
        )} />
        {/* NAV ITEMS */}
        <Route path='/test_error_test_test' render={() => (
          <ErrorBoundary>
            <TestErrorBoundary />
          </ErrorBoundary>
        )} />
        <Route path='/' render={() => (
          <ErrorBoundary>
              <LandingPage />
          </ErrorBoundary>
        )} />
      </Switch>
    </React.Fragment>
  )

}
