import React from 'react'
import { Context } from '../Store.js'
import { Notification } from '@vds/notifications';

export default function ConnectedNotification () {
  const { state, dispatch } = React.useContext(Context)
  const { error  } = state  
  
  function handleClose() {
    dispatch({type: 'CLEAR_ERROR' })
  }

  if (!error) {
    return null
  }

  return (
    <div className='pb-4'>
      <Notification 
        onCloseButtonClick={handleClose}
        // disableAnimation={true}
        type="error" 
        id="error-bar" 
      >
        {error}
      </Notification>
    </div>
  )
}
