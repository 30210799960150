import React from 'react'
import Store from './Store.js'
import {
  BrowserRouter as Router,
} from "react-router-dom";
import Routes from './Routes'


const App = () => {
  return (
    <Store>
      <Router>
        <Routes />
      </Router>
    </Store>
  )
}

export default App
