import React from 'react'
import { Formik } from 'formik'
import { InputField } from '@vds/inputs';
import { Button } from '@vds/buttons'
import { useHistory } from "react-router-dom";
import { Context } from '../Store.js'
import { apiURL } from '../constants'
import axios from 'axios'
// import { updateVerifyCoinFormFieldValues } from '../formHelpers'

export default function VerifyCoinStepTwo () {
  const {dispatch, state, actions } = React.useContext(Context)
  const { verifyCoinWizard, coin } = state

  let history = useHistory()
  console.log( actions )
  async function fetchUser (values, setSubmitting) {
    try {
      const res = await axios.get(`${apiURL}/user_by_email.json`, { params: {email: values.email}})
      const user = res.data
      await dispatch({type: "SET_USER", user })
      if (!user.coin || (user.coin.id !== coin.id)) {
        throw "not_user_coin"
      }

      const { formFields } = verifyCoinWizard
      const newFormFields = Object.assign({}, formFields, values)

      dispatch({
        type: "UPDATE_VERIFY_COIN_WIZARD",
        verifyCoinWizard: Object.assign({}, verifyCoinWizard, { formFields: newFormFields })
      })
      dispatch({type: "SET_USER", user })
      if (user.is_owner) {
        if (user.verified) {
          history.push(`/pass_coin/${coin.number}`)
        } else {
          history.push(`/recipient_confirmation`)
        }
      } else {
        history.push(`/follow_coin/${coin.number}`)
      }
      dispatch({type: 'CLEAR_ERROR' })
    } catch (error) {
      const is404 = error && error.response && error.response.status === 404

      if (is404 || error === 'not_user_coin') {
        dispatch({type: 'SET_ERROR', error: "It doesn't look like this Coin is associated with you yet. Check that you've entered the number correctly. If the error persists, you’ll need to contact support@support.com."})
      } else {
        dispatch({type: 'SET_ERROR', error: 'Looks like we have a problem. Please refresh this page.'})
      }
      dispatch({
        type: "UPDATE_VERIFY_COIN_WIZARD",
        verifyCoinWizard: Object.assign({}, verifyCoinWizard, { index: 0 })
      })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      isValidating={false}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Email Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        fetchUser(values, setSubmitting)
        // history.push('/recipient_confirmation')
        // setSubmitting(false)
      }}
      >
      {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      /* and other goodies */
      }) => (
      <form className="w-100 form-group" onSubmit={handleSubmit}>
      <div className="fixed-width d-flex justify-content-center mb-4 m0auto">
        <div className="w-100">
          <InputField
            label="What is your email address?"
            placeholder="Your Email"
            width="inherit"
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={!!errors.email && !!touched.email}
            errorText={errors.email}
          />
        </div>
      </div>
      <div className="w-100 d-flex justify-content-left">
        <Button type="secondary" id="register-btn" disabled={isSubmitting}>
          Next
        </Button>
      </div>
      </form>
      )}
    </Formik>
  )
}
