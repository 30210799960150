import React, { useState, useContext } from 'react';
import { InputField } from '@vds/inputs';
import { Button } from '@vds/buttons';
import { Body } from '@vds/typography';
import ForwardTogetherHand from '../assets/ForwardTogetherHand.png'
import { Context } from '../Store.js'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { apiURL } from '../constants'
import { verizonTheme as theme } from '@vds/theme';
// import * as yup from 'yup';

// let schema = yup.object().shape({
//   coinNumber: yup.number().required()
// })

function DarkCoinInput (props) {
  const {dispatch} = useContext(Context)
  const [coinNumber, setCoinNumber] = useState('')
  const [fetching, setFetching] = useState(false)
  let history = useHistory()

  function fetchCoin () {
    setFetching(true)
    axios.get(`${apiURL}/coins/${coinNumber}.json`)
    .then((res) => {
      const { data } = res
      dispatch({ type: "SET_COIN", coin: data })
      dispatch({ type: "SET_USER", user: data.original_owner })
      setFetching(false)
      if (data.transfers.length > 1) {
        history.push(`/follow_coin/${coinNumber}`)
      } else {
        history.push(`/pass_coin/${coinNumber}`)
      }
    })
    .catch(error => {
      let displayError = ''

      if (error && error.response && error.response.status === 404) {
        displayError = "Whoops. That wasn't a recognized Coin number. Please try re-entering."
      } else {
        displayError = "Whoops. That wasn't a recognized Coin number. Please try re-entering."
      }
      dispatch({ type: "SET_ERROR", error: displayError})
      setFetching(false)
    })
  }

  function handleClick () {
    fetchCoin()
  }

  function handleChange(field) {
    setCoinNumber(field.target.value)
  }

  return (
    <div className="wizard-container bg-black container-fluid d-flex justify-content-center">
    <div className="fixed-width bg-black">
      <div className="w-100 mx-auto">
        <img src={ForwardTogetherHand} alt="Logo" />
      </div>
      <div className="mx-auto w-100 sm:w-3/4 md:w-1/2">

        <div className=" w-5/6 mx-auto mt-10 mb-4 flex justify-center">
          <Body color={theme.white} size="large" viewport="desktop" primitive="p">It's time to pass this coin on to an employee who you believe has the characteristics to be a successful Purpose Champion.</Body>
        </div>

        <div className=" w-5/6 mx-auto mb-4 flex justify-center">
          <Body color={theme.white} size="large" viewport="desktop" primitive="p">First, record the number on the back of your coin below.</Body>
        </div>

        <div className="w-100 mb-3">
          <InputField
            label="What's your coin number?"
            placeholder="1234"
            width="inherit"
            type='number'
            onChange={handleChange.bind(this)}
          />
          <div className="w-100 text-red-700 font-bold">
            {/* { coinData !== null && coinData.status === "error" ? coinData.message : "" } */}
          </div>
        </div>

        <div className="w-100">
          <div className="w-100 d-flex justify-content-left">
            <Button type="secondary" id="register-btn" onClick={handleClick} disabled={coinNumber.length === 0 || fetching}>Register Now</Button>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default DarkCoinInput;
