import React from 'react';
import { Title } from '@vds/typography'
import { Button } from '@vds/buttons';
import { Body } from '@vds/typography';
import { Link,  useHistory } from 'react-router-dom'
import { Context } from '../Store.js'
import { Line } from '@vds/lines';
import ConnectedNotification from './ConnectedNotification'

function PassCoin () {
  const { state } = React.useContext(Context)
  const { user, coin } = state
  const history = useHistory()

  if (!user) {
    history.replace('/coin')
    return null
  }


  return (
    <div className="container">
      <ConnectedNotification />
      <div className="px-6 py-8">
        <div className="w-100 mb-2">
          <Title size="large" primitive="h1">{`Hi, ${user.first_name}.`}</Title>
          <Line type="heavy"/>
        </div>

        <div className="w-100 mb-4">
          <Body size="large" viewport="desktop" primitive="h2">Thank you again for being a champion of the Verizon purpose and helping us to move #ForwardTogether.</Body>
        </div>

        <div className="w-100 mb-0">
          <div className="">
            <Title size="small" primitive="h4">You Have</Title>
          </div>
        </div>

        <div className="w-100 mb-4">
          <div className="mt-4">
          <Title size="large" primitive="h3"><span className="big">{coin.days_left_current}</span> days left</Title>
          </div>
        </div>

        <div className="w-100 mb-4">
          <Body size="large" viewport="desktop" primitive="h2">to pass your Purpose Coin on to someone who demonstrates an outstanding commitment to our purpose.</Body>
        </div>

        <div className="w-100 mb-4">
          <Body size="large" viewport="desktop" primitive="h2">Don't forget to give them the coin itself when you let them know they've been chosen.</Body>
        </div>
      </div>

      <div className="w-100 d-flex justify-content-left">
        <div className="w-100">
          <Link to="/select_recipient">
            <Button className="w-100-mobile">Pass the Coin</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PassCoin;
