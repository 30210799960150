import React from 'react';
import { Title } from '@vds/typography'
import { Body } from '@vds/typography';
import { Button } from '@vds/buttons';
import { Link, useHistory } from 'react-router-dom'
import { Context } from '../Store.js'
import { TextLink } from '@vds/buttons'
import { Line } from '@vds/lines';


function SuccessfulRegistration () {
  let history = useHistory()
  const { state } = React.useContext(Context)
  const { coin, user } = state

  if (!coin) {
    history.push("/coin")
    return null
  }

  return (
    <div className="container mx-auto w-100 sm:w-3/4 md:w-1/2 flex flex-col items-center justify-center">
      <div className="p-6">
        <div className="w-100 mb-4 leading-loose">
          <Title size="large" primitive="h1">
            {
              user
              ? `Congratulations, ${user.first_name}!`
              : 'Congratulations!'
            }
          </Title>
          <Line type="heavy"/>
        </div>

        <div className="mb-4">
          <Body size="large">
            As a champion of our new purpose, to create the networks that move the world forward, you'll receive a monthly email asking you to:
          </Body>
        </div>

        <div className="mb-4">
          <ul>
            <li><strong>Be</strong> an evangelist</li>
            <li><strong>Identify</strong> stories of purpose-driven behavior</li>
            <li><strong>Share</strong> experiences using #ForwardTogether</li>
            <li><strong>Recognize</strong> the next Purpose Champion</li>
          </ul>
        </div>

        <div className="mb-4">
          <Body size="large">
            Everything you need to know is in your Purpose Champion Handbook. <TextLink href="/PurposeChampionToolkit.pdf">You can download here.</TextLink>
          </Body>
        </div>

      </div>

      <div className="w-100 d-flex justify-content-left">
        <Link to={`/pass_coin/${coin.number}`}>
          <Button className="w-100-mobile">Get Started</Button>
        </Link>
      </div>

    </div>
  )
}

export default SuccessfulRegistration;
