import React from 'react';
import { Body } from '@vds/typography'
import { Context } from '../Store.js'
import { CarouselBars } from '@vds/carousels';
import ForwardTogetherHand from '../assets/ForwardTogetherHand.png'
import VerifyCoinStepOne from './VerifyCoinStepOne'
import VerifyCoinStepTwo from './VerifyCoinStepTwo'
import styled from 'styled-components'
import {useTransition, animated} from 'react-spring'
import { verizonTheme as theme } from '@vds/theme';
import { UnorderedList, ListItem } from '@vds/lists';
import { TextLink } from '@vds/buttons'
import { Notification } from '@vds/notifications';
import { Button } from '@vds/buttons';
import { Link } from 'react-router-dom'

const Container = styled.div`
  width: 60px;
`;

const anims = [
  ({style, key, item}) => {
    return (
      <animated.div key={key} className='w-100 welcome-slider' style={style}>
        <VerifyCoinStepOne />
        <div className='w-100 d-flex justify-content-center'>
          <Container>
            <CarouselBars
              id='carousel-bottom-verify'
              uniqueId="react-carousel-bars-id"
              selectedSlide={item + 1}
              slideCount={2}
            />
          </Container>
        </div>
      </animated.div>
    )
  },
  ({style, key, item}) => {
    return (
      <animated.div key={key} className='w-100 welcome-slider' style={style}>
        <VerifyCoinStepTwo />
        <div className='w-100 d-flex justify-content-center'>
          <Container>
            <CarouselBars
              id='carousel-bottom-verify'
              uniqueId="react-carousel-bars-id"
              selectedSlide={item + 1}
              slideCount={2}
            />
          </Container>
        </div>
      </animated.div>
    )
  }
]


export default function LandingPage () {

  const { state, dispatch } = React.useContext(Context)
  const { verifyCoinWizard, error } = state
  const { index } = verifyCoinWizard
  // const [toggle, set] = React.useState(false)

  const transitions = useTransition(index, null, {
    from: { position: 'absolute', opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  })

  return (
    <div className="wizard-container bg-black container-fluid d-flex justify-content-center">
    <div className="fixed-width row bg-black">
        <div className="w-full mx-auto">
          <img src={ForwardTogetherHand} alt="Logo" />
        </div>

        <div className="bg-black coin-entry">
          <div className="mb-4">
            <Body color={theme.white} size="large">
              Purpose-driven culture transformation happens when every member of the team is aligned around a shared purpose.
              {/* Congratulations, [Recipient Name]! [Giver Name] selected you as a Purpose Champion.  */}
            </Body>
          </div>

          <div className="mb-4">
            <Body color={theme.white} size="large">
              The Purpose Coin is a powerful symbol that helps us recognize those who stand out as dedicated stewards of that shared purpose—V Teamers who lead by example and inspire those around them to put the purpose at the center of all that they do. It's also a great way for us to track the momentum of this movement as it spreads throughout all of Verizon and helps us be the company the world needs us to be.
            </Body>
          </div>

          <div className="mb-4">
            <Body color={theme.white} size="large">
              Let's get inspired. Together.
            </Body>
          </div>

          <div className="w-100 flex justify-center">
            <div className="">
              <Link to={`/coin`}>
                <Button type="primary">I Have a Coin</Button>
              </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
