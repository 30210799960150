import Welcome from './Welcome'
// import CoinInput from './CoinInput'
import DarkCoinInput from './DarkCoinInput'
import RecipientCoinInput from './RecipientCoinInput'
import RecipientEmailInput from './RecipientEmailInput'
// import RecipientConfirmation from './RecipientConfirmation'
import RecipientFormWizard from './RecipientFormWizard'
import ProgramInfo from './ProgramInfo'
import PassCoin from './PassCoin'
import FollowCoin from './FollowCoin'
import SuccessfulTransfer from './SuccessfulTransfer'
import SuccessfulRegistration from './SuccessfulRegistration'
import PreregisteredGreeting from './PreregisteredGreeting'

// import Nav from './Nav'

const Screens = {
  Welcome, 
  // CoinInput,
  DarkCoinInput,
  RecipientCoinInput,
  RecipientEmailInput,
  RecipientFormWizard,
  // RecipientConfirmation,
  ProgramInfo,
  PassCoin,
  FollowCoin,
  SuccessfulTransfer,
  SuccessfulRegistration,
  PreregisteredGreeting
}

export default Screens
