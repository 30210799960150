import React from 'react';
import { Title } from '@vds/typography'
// import { InputField } from '@vds/inputs';
import { Button } from '@vds/buttons';
import { Body } from '@vds/typography';
import { Line } from '@vds/lines';

function ProgramInfo() {

  function handleClick () {
    window.location = `/pass`
  }

  return (
    <div className="container mx-auto h-screen w-100 sm:w-3/4 md:w-1/2 flex flex-col items-center justify-center">
      <div className="w-100 mb-4 flex justify-center">
        <Title size="medium" primitive="h1">About Purpose Coin</Title>
        <Line type="heavy"/>
      </div>

      <div className="w-100 mb-4 flex justify-center">
        <div className="">
          <Body size="large" viewport="desktop" primitive="h2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate itaque dignissimos aliquid rem quia molestias nulla reprehenderit deserunt, tempore quis delectus sequi veniam dicta porro sit distinctio architecto, iste asperiores.</Body>
        </div>
      </div>

      <div className="w-100 flex justify-center">
        <div className="">
          <Button onClick={handleClick}>Pass Coin</Button>
        </div>
      </div>
    </div>
  )
}

export default ProgramInfo;
