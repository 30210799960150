import React, { useEffect } from 'react';
import { Title } from '@vds/typography'
import { Button } from '@vds/buttons';
import { Body } from '@vds/typography';
import { InputField } from '@vds/inputs';
import { usePosition } from '../UsePosition';
import { Context } from '../Store.js'
import { Formik } from 'formik'
import axios from 'axios'
import { apiURL } from '../constants'
import { useHistory } from "react-router-dom";
import { Line } from '@vds/lines';
import ConnectedNotification from './ConnectedNotification'


function RecipientConfirmation () {
  const [zip, setZip] = React.useState('')
  const {dispatch, state} = React.useContext(Context)
  const { coin, verifyCoinWizard } = state
  const { latitude, longitude } = usePosition();
  let history = useHistory()

  const email = verifyCoinWizard.formFields.email

  async function handleSubmitForm (opts={}) {
    const { formFields, setSubmitting } = opts

    try {
      const res = await axios.post(`${apiURL}/coins/update_owner`, formFields, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      console.log(res.data)
      dispatch({
        type: "SET_USER",
        user: res.data
      })
    } catch (e) {
      dispatch({
        type: "SET_ERROR",
        error: "something went wrong"
      })
    } finally {
      setSubmitting(false)
      history.push("/successful_registration")
    }
  }

  async function fetchZip () {
    try {
      let zips = []
      const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCGt9NBLULEoJ7uYDYnNAUiahcyNCJWmLk`)
      if (res.data && res.data.results) {
        res.data.results.forEach((result) => {
          result.address_components.forEach((component) => {
            if (component.types.includes("postal_code") && component.types.length === 1) {
              zips.push(component.short_name)
            }
          })
        })
      }
      if (zips.length > 0 && zip === '') {
        setZip(zips[0])
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (latitude && longitude) {
      fetchZip()
    }
  }, [latitude, longitude])

  if (!coin) {
    history.push("/coin")
    return null
  }

  const { owner } = coin

  if (!owner) {
    history.push("/coin")
    return null
  }

  let first_name = ''
  let last_name = ''

  if (owner) {
    first_name = owner.first_name
    last_name  = owner.last_name
  }

  function handleZipChange (event) {
    setZip(event.target.value)
  }

  return (
    <div className="container">
      <ConnectedNotification />
      <div className="p-6">


        <div className="w-100 mb-4">
          <Title size="large" primitive="h1">Let's get started.</Title>
          <Line type="heavy"/>
        </div>

        <div className="w-100 mb-4">
            <Body size="large" viewport="desktop" primitive="h2">Congratulations on being selected as a Purpose Champion and receiving a Purpose Coin. Please confirm your information below.</Body>
        </div>



        <Formik
          initialValues={{  first_name: first_name, last_name: last_name,  email: email, emailConfirmation: '', }}
          isValidating={false}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = 'Email Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            if (!values.first_name) {
              errors.first_name = 'First name is required';
            }
            if (!values.last_name) {
              errors.last_name = 'Last name is required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            const formFields = Object.assign({}, values, {
              coin_id: coin.id,
              latitude: latitude,
              longitude: longitude,
              zip: zip
            })
            handleSubmitForm({
              formFields,
              setSubmitting
            })

          }}
        >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="w-100 mb-4 flex flex-col justify-center">
            <div className="w-100 mb-4">
              <InputField
                label="What's your first name?"
                placeholder="First Name"
                width="inherit"
                type="first_name"
                name="first_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.first_name}
                error={!!errors.first_name && !!touched.first_name}
                errorText={errors.first_name}
              />
            </div>
            <div className="w-100 mb-4">
              <InputField
                label="What's your last name?"
                placeholder="Last Name"
                width="inherit"
                type="last_name"
                name="last_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.last_name}
                error={!!errors.last_name && !!touched.last_name}
                errorText={errors.last_name}
              />
            </div>
            <div className="w-100 mb-4">
              <InputField
                label="What is your email address?"
                placeholder="Recipient's Email"
                width="inherit"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={!!errors.email && !!touched.email}
                errorText={errors.email}
              />
              </div>
              <div className="w-100 mb-4">
                <InputField
                  label="What's your zip code?"
                  placeholder="Your Zip Code"
                  width="inherit"
                  id="autocomplete"
                  value={zip}
                  onChange={handleZipChange}
                />
            </div>
          </div>



          <div className="w-100 d-flex justify-content-left">
            <Button className="w-100-mobile" type='submit' disabled={isSubmitting}>
              Next
            </Button>
          </div>
        </form>
        )}
      </Formik>

      <div className="w-100">
        <div id="map"></div>
      </div>
    </div>
  </div>
  )
}

export default RecipientConfirmation;
