import React from 'react';
import { Title } from '@vds/typography'
import { Body } from '@vds/typography';
import { Line } from '@vds/lines';

export default function About () {

  return (
    <div className="container mx-auto w-100">
      <div className="p-6">
        <div className="w-3/4 mb-4">
          <Title size="large" primitive="h1">About the Purpose Coin</Title>
          <Line type="heavy"/>
        </div>

        <div className="w-100 mb-4">
          <Body size="large" viewport="desktop" primitive="h2">Thanks for taking part in the start of this great movement. Your participation helps spread our new purpose and strengthen our commitment to moving #ForwardTogether.</Body>
        </div>

        <div className="w-100 mb-4">
          <Body size="large" viewport="desktop" primitive="h2">We know you're excited to recognize and reward someone who shows purpose in action in all that they do.</Body>
        </div>
      </div>
    </div>
  )
}
