import axios from 'axios'
import { apiURL } from './constants'

export async function updateRecipientFormFieldValues (opts={}) {
  const {
    index, 
    dispatch, 
    recipientFormWizard, 
    values,
    submitting,
    setSubmitting,
    coin_id,  
    history
  } = opts
  const { formFields } = recipientFormWizard    
  const updatedFormFields = Object.assign({}, formFields, values)
  const newRecipientFormWizard = Object.assign({},
    recipientFormWizard,
    { formFields: updatedFormFields },
    index 
    ? { index: index }
    : {}
  )
  dispatch({ 
    type: "UPDATE_RECIPIENT_FORM_WIZARD", 
    recipientFormWizard: newRecipientFormWizard 
  })
  if (!submitting) {
    setSubmitting(false);
    return
  }

  const transferFormFields = Object.assign({}, updatedFormFields, { coin_id })

  try {
    const res = await axios.post(`${apiURL}/transfers.json`, transferFormFields, {
      headers: {
        'Content-Type': 'application/json',
      }
    }) 

    dispatch({
      type: 'SET_TRANSFER',
      transfer: res.data
    })
    history.push('/upload')
  } catch (error) {
    const data = error && error.response && error.response.data
    if (data) {
      if (error.response.status === 422) {
        const childErrorMessage = Object.values(data).reduce((errStr, el) => {
          return errStr += el.join(", ") + ' '
        },'')
        dispatch({
          type: 'SET_ERROR', 
          error: childErrorMessage
        })
        dispatch({
          type: 'UPDATE_RECIPIENT_FORM_WIZARD',
          recipientFormWizard: Object.assign({}, recipientFormWizard, {index: 0})
        })
      } else {
        dispatch({
          type: 'UPDATE_RECIPIENT_FORM_WIZARD',
          recipientFormWizard: Object.assign({}, recipientFormWizard, {index: 0})
        })
      }
    }
    console.log(error)
  } finally {
    setSubmitting(false)
  }
}
