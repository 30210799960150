import React from 'react';
import { Body } from '@vds/typography'
import { Context } from '../Store.js'
import { CarouselBars } from '@vds/carousels';
import ForwardTogetherHand from '../assets/ForwardTogetherHand.png'
import VerifyCoinStepOne from './VerifyCoinStepOne'
import VerifyCoinStepTwo from './VerifyCoinStepTwo'
import styled from 'styled-components'
import {useTransition, animated} from 'react-spring'
import { verizonTheme as theme } from '@vds/theme';
import { Notification } from '@vds/notifications';

const Container = styled.div`
  width: 60px;
`;

const anims = [
  ({style, key, item}) => {
    return (
      <animated.div key={key} className='w-100 welcome-slider' style={style}>
        <VerifyCoinStepOne />
        <div className='w-100 d-flex justify-content-left hidden'>
          <Container>
            <CarouselBars
              id='carousel-bottom-verify'
              uniqueId="react-carousel-bars-id"
              selectedSlide={item + 1}
              slideCount={2}
            />
          </Container>
        </div>
      </animated.div>
    )
  },
  ({style, key, item}) => {
    return (
      <animated.div key={key} className='w-100 welcome-slider' style={style}>
        <VerifyCoinStepTwo />
        <div className='w-100 d-flex justify-content-left hidden'>
          <Container>
            <CarouselBars
              id='carousel-bottom-verify'
              uniqueId="react-carousel-bars-id"
              selectedSlide={item + 1}
              slideCount={2}
            />
          </Container>
        </div>
      </animated.div>
    )
  }
]


export default function VerifyCoinWizard () {

  const { state, dispatch } = React.useContext(Context)
  const { verifyCoinWizard, error } = state
  const { index } = verifyCoinWizard
  // const [toggle, set] = React.useState(false)

  const transitions = useTransition(index, null, {
    from: { position: 'absolute', opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  })

  return (
    <div className="wizard-container bg-black container-fluid d-flex justify-content-center">
    <div className="fixed-width bg-black">
        <div className="w-full mx-auto">
          <img src={ForwardTogetherHand} alt="Logo" />
        </div>

        <div className="">
          <div className="mb-4">
            <Body color={theme.white} size="large">
              The Purpose Coin is a powerful symbol that helps us recognize those who stand out as dedicated stewards of that shared purpose.
              {/* Congratulations, [Recipient Name]! [Giver Name] selected you as a Purpose Champion.  */}
            </Body>
          </div>

          <div className="mb-4">
            <Body color={theme.white} size="large">
              Enter the number on your coin to begin.
            </Body>
          </div>

          {
            error &&
              <Notification onCloseButtonClick={() => {
                  dispatch({ type: "CLEAR_ERROR" })
                }}
                disableAnimation={true} type="warning" id="error-bar">
                {error}
              </Notification>
          }

          <div className='fixed-width m0auto welcome-slider-wrapper'>
          {
            transitions.map(({ item, key, props }) => {
              const Form = anims[item]
              return <Form style={props} key={key} item={item} />
            })
          }
          </div>
        </div>
      </div>
    </div>
  )
}
