import React, { useContext } from 'react';
import { Title } from '@vds/typography'
import { Button } from '@vds/buttons';
import { Body } from '@vds/typography';
import { Context } from '../Store.js'
import Nav from './Nav'
import { Line } from '@vds/lines';

const PreregistedGreeting = (props) => {
  const { state } = useContext(Context)

  function handleClick () {
    window.location = `/pass`
  }

  return (
    <>
      <Nav />
      <div className="container mx-auto w-100 sm:w-3/4 md:w-1/2 flex flex-col items-center justify-center">
        <div className="p-6">
          <div className="w-100 mb-6">
            <Title size="large" primitive="h1">Hey, {state.owner.first_name}.</Title>
            <Line type="heavy"/>
          </div>

          <div className="w-100 mb-4">
            <Body size="large" viewport="desktop" primitive="h2">The Purpose Coin you received is now registered and ready to be passed on.</Body>
          </div>

          <div className="w-100 mb-4">
            <Body size="large" viewport="desktop" primitive="h2">Champions of our new purpose to “create the networks that move the world forward” receive a monthly email asking them to <strong>share reminders</strong> of important programs, <strong>identify stories</strong> of their peers who are transforming our culture, <strong>socialize the hashtag</strong> #ForwardTogether, and ultimately <strong>pass on the coin</strong> to another champion.</Body>
          </div>
        </div>

        <div className="w-100 flex justify-center">
          <div className="">
            <Button className="w-100-mobile" onClick={handleClick}>Pass the Coin</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PreregistedGreeting;
