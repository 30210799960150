import React, { useState } from 'react';
import Logo from '../assets/logo-white.png'
// import QuestionIcon from '../assets/QuestionIcon.png'
import { Context } from '../Store.js'
import { TitleAccented } from '@vds/type-lockups';
import HamburgerIcon from '../assets/menuicon.png'
import ClosedIcon from '../assets/menuiconclose.png'
import { Link } from 'react-router-dom'

function Nav({hideLogo}) {
  const { state } = React.useContext(Context)
  const [menuOpen, setMenuOpen] = useState(false)
  const { user, transfer, coin } = state

  function handleClick () {
    setMenuOpen(!menuOpen)
  }

  function closeMenu () {
    setMenuOpen(false)
  }

  function getHomePath () {
    let homePath = '/'
    if (coin && user && transfer) {
      homePath = `/follow_coin/${coin.number}`
    } else if ( coin && coin.transfers && coin.transfers.length === 1) {
      homePath = `/pass_coin/${coin.number}`
    } else if ( coin && user && user.is_owner) {
      if (user.verified) {
        homePath = `/pass_coin/${coin.number}`
      } else {
        homePath = `/recipient_confirmation`
      }
    } else if ( coin && user ) {
      homePath = `follow_coin/${coin.number}`
    }
    return homePath
  }

  let homePath = getHomePath()

  return (
    <div className={"nav-bar bg-black justify-content-center " + (menuOpen ? ' nav-expanded' : '')}>
    <div class="nav-inner bg-black">
      <div className="nav-contents d-flex align-items-center justify-content-center text-white bg-black">
        <div className="mr-auto nav-logo">
          {
            <img src={Logo} alt="Logo" className="h-10" style={ hideLogo ? {visibility: 'hidden'} : {}} />
          }

        </div>
        <div className={(menuOpen ? ' nav-open' : '')}>
          {
            menuOpen ?
            <button className="nav-button" onClick={handleClick}>
              <img src={ClosedIcon} alt="ClosedIcon" className="nav-icon nav-close"/>
            </button>
          :
            <button className="nav-button" onClick={handleClick}>
              <img src={HamburgerIcon} alt="HamburgerIcon" className="nav-icon nav-hamburger"/>
            </button>
          }
          {/* {
            menuOpen
            ?
              <button className="nav-button" onClick={handleClick}>
                <span className="nav-close">⨉</span>
              </button>
            :
              <button className="nav-button" onClick={handleClick}>
                <span className="nav-hamburger">☰</span>
              </button>
          } */}
        </div>
      </div>
      <div className={"nav-open " + (menuOpen ? '' : 'hidden')}>
        <div className="nav-items">
          <div className="home-wrapper mb-2">
            <Link to={homePath} onClick={closeMenu}>
              <TitleAccented>Home</TitleAccented>
            </Link>
          </div>
          <div className="mb-2">
            <Link to="/about" onClick={closeMenu}>
              <TitleAccented>About</TitleAccented>
            </Link>
          </div>
          <div className="mb-2">
            <Link to="/help" onClick={closeMenu}>
              <TitleAccented>Help</TitleAccented>
            </Link>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Nav;
