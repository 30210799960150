import React from 'react'
import { Context } from '../Store.js'
import RecipientStepOne from './RecipientStepOne'
import RecipientStepTwo from './RecipientStepTwo'
import RecipientStepThree from './RecipientStepThree'
import {  useHistory } from 'react-router-dom'

export default function RecipientFormWizard () {
  const { state } = React.useContext(Context)
  const { recipientFormWizard, user, coin} = state
  const { index } = recipientFormWizard
  const history = useHistory()

  if (!user || !coin) {
    history.replace('/coin')
    return null
  }

  console.log("RELOADING THIS", index)
 if (index === 0) {
  return <RecipientStepOne />
 }
 if (index === 1) {
  return <RecipientStepTwo />
 }
 if (index === 2) {
  return <RecipientStepThree />
 }

}