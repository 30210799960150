import React from 'react';
import { Title } from '@vds/typography'
import { Button } from '@vds/buttons';
import { Body } from '@vds/typography';
import { Line } from '@vds/lines';
import { Context } from '../Store.js'
import { TextLink } from '@vds/buttons'
import { useHistory, Link } from 'react-router-dom'

function ProgramInfo() {
  const { state } = React.useContext(Context)
  const { user, coin } = state
  let history = useHistory()

  if (!user) {
    history.push('/coin')
    return null
  }


  return (
    <div className="container mx-auto h-screen w-100 sm:w-3/4 md:w-1/2 flex flex-col items-center justify-center">
      <div className="w-100 mb-4 flex justify-center">
        <Title size="large" primitive="h1">{
          user
          ? `Congratulations, ${user.first_name}!`
          : 'Congratulations!'
        }</Title>
        <Line type="heavy"/>
      </div>

      <div className="w-100 mb-4 flex justify-center">
        <div className="mb-4">
          <Body size="large" viewport="desktop" primitive="h2">As a champion of our new purpose, to create the networks that move the world forward, you'll receive a monthly email asking you to:</Body>
        </div>
        <div className="mb-4 ">
          <ul>
            <li><strong>Be</strong> an evangelist</li>
            <li><strong>Identify</strong> stories of purpose-driven behavior</li>
            <li><strong>Share</strong> experiences using #ForwardTogether</li>
            <li><strong>Recognize</strong> the next Purpose Champion</li>
          </ul>
        </div>
        <div className="mb-4">
          <Body size="large">
            Everything you need to know is in your Purpose Champion Handbook. <TextLink href="/PurposeChampionToolkit.pdf">You can download here.</TextLink>
          </Body>
        </div>
      </div>

      <div className="w-100 flex justify-center">
        <div className="">
          <Link to={`/pass_coin/${coin.number}`}>
            <Button className="w-100-mobile">Get Started</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ProgramInfo;
