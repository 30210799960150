import React from 'react';
import { Title } from '@vds/typography'
import { Body } from '@vds/typography';
import { Button } from '@vds/buttons';
import { Line } from '@vds/lines';

function SuccessfulTransfer() {

  function handleClick () {
    window.location = `/`
  }

  return (
    <div className="container mx-auto w-100 sm:w-3/4 md:w-1/2 flex flex-col items-center justify-center">
      <div className="p-6">
        <div className="w-100 mb-2 leading-loose">
          <Title size="large" primitive="h1">Congratulations!</Title>
          <Line type="heavy"/>
        </div>

        <div className="w-100 my-16 flex justify-center">
          <div className="">
            <Body size="large" viewport="desktop" primitive="h2">Your Purpose Coin was successfully registered to <strong>Jane Smith.</strong></Body>
          </div>
        </div>

        <div className="w-100 mb-4 flex justify-center">
          <div className="">
            <Body size="large" viewport="desktop" primitive="h2">Please be sure to physically deliver the coin to [name] abd ket them know why you've chose them to be a Purpose Champion.</Body>
          </div>
        </div>

        <div className="w-100 mb-4 flex justify-center">
          <div className="">
            <Body size="large" viewport="desktop" primitive="h2">Don't forget to come back and track your Coin and the progress of every Purpose Coin.</Body>
          </div>
        </div>
      </div>

      <div className="w-100 d-flex justify-content-left">
        <Button onClick={handleClick}>Track Your Coin</Button>
      </div>

    </div>
  )
}

export default SuccessfulTransfer;
