import React from 'react'
import { Context } from '../Store.js'
import { Title, Body } from '@vds/typography'
import { Link } from 'react-router-dom'
import { Button } from '@vds/buttons';
import { Line } from '@vds/lines';

export default function ThankYou () {
  const { state } = React.useContext(Context)
  const { transfer, coin } = state

  let newUser = null
  if (transfer && transfer.user) {
    newUser = transfer.user
  }

  return (
    <div className="container mx-auto mt-10 w-5/6 sm:w-3/4 md:w-1/2 flex flex-col">
      <div className ='justify-start'>
        <Title size="large" primitive="h1">Thank You</Title>
        <Line type="heavy"/>
      </div>

      <div className="w-100 mt-4 mb-4">
        <Body size="large" viewport="desktop" primitive="p">
        {
          newUser
          ? `Your purpose coin was successfully registered to ${newUser.first_name} ${newUser.last_name}.`
          : 'Your purpose coin was successfully transferred.'
        }
        </Body>
      </div>

      <div className="w-100 mt-4 mb-4">
        <Body size="large" viewport="desktop" primitive="p">
          Please be sure to physically deliver the coin { newUser ? `to ${newUser.first_name} ${newUser.last_name}` : '' } and let them know why you've chosen them to be a Purpose Champion.
        </Body>
      </div>

      <div className="w-100 mt-4 mb-4">
        <Body size="large" viewport="desktop" primitive="p">
          Don't forget to come back and track your Coin and the progress of every Purpose Coin.
        </Body>
      </div>

      <div className="w-100 d-flex justify-content-left">
        <Link to={`/follow_coin/${coin.number}`}>
          <Button class="w-100-mobile">Track Your Coin</Button>
        </Link>
      </div>
    </div>
  )
}
