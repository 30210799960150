import React from 'react';
import { Title, Body } from '@vds/typography'
import { Line } from '@vds/lines';
import ConnectedNotification from './ConnectedNotification'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({
      hasError: true
    })
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="container">
          <ConnectedNotification />
          <div className="px-6 py-8">
            <div className="w-100 mb-2">
              <Title size="large" primitive="h1">Looks like we have a problem</Title>
              <Line type="heavy"/>
              <Body size="large" viewport="desktop" primitive="h2">
                Please refresh the page.
              </Body>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children;
  }
}
