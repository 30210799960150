import React from 'react'
import { Formik } from 'formik'
import { InputField } from '@vds/inputs';
import { Button } from '@vds/buttons'
import { Context } from '../Store.js'
import axios from 'axios'
import { apiURL } from '../constants'
import { useHistory } from "react-router-dom";
// import { updateVerifyCoinFormFieldValues } from '../formHelpers'


export default function VerifyCoinStepOne () {
  const {dispatch, state} = React.useContext(Context)
  const { verifyCoinWizard } = state
  let history = useHistory()

  async function fetchCoin (coin_number, setSubmitting) {
    try {
      const res = await axios.get(`${apiURL}/coins/${coin_number}.json`)
      const coin = res.data
      dispatch({ type: "SET_COIN", coin: coin })
      // if (coin.transfers.length === 1) {
      //   dispatch({ type: "SET_USER", user: coin.original_owner })
      //   history.push('/leader_welcome')
      //   return
      // }
      dispatch({ type: "UPDATE_VERIFY_COIN_WIZARD", verifyCoinWizard: Object.assign({}, verifyCoinWizard, {index: 1})})
      dispatch({ type: "CLEAR_ERROR" })
    } catch (error) {
      let displayError = ''
      if (error && error.response && error.response.status === 404) {
        displayError = "Whoops. That wasn't a recognized Coin number. Please try re-entering."
      } else {
        displayError = "Whoops. That wasn't a recognized Coin number. Please try re-entering."
      }
      dispatch({ type: "SET_ERROR", error: displayError})
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{ coin_number: '' }}
      isValidating={false}
      validate={values => {
        const errors = {};

        if (!values.coin_number) {
          errors.coin_number = 'Coin number required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        fetchCoin(values.coin_number, setSubmitting)
      }}
      >
      {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      /* and other goodies */
      }) => (
      <form className="w-100 form-group" onSubmit={handleSubmit}>
      <div className="fixed-width d-flex justify-content-left mb-4 m0auto">
        <div className="w-100">
          <InputField
            label="What's your coin number?"
            placeholder="1234"
            width="inherit"
            type="coin_number"
            name="coin_number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.coin_number}
            error={!!errors.coin_number && !!touched.coin_number}
            errorText={errors.coin_number}
          />
        </div>
      </div>
      <div className="w-100 d-flex justify-content-left">
        <Button type="secondary" id="register-btn" disabled={isSubmitting}>
          Next
        </Button>
      </div>
      </form>
      )}
    </Formik>
  )
}
